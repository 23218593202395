var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pdf"},[_c('q-page-container',[_c('q-page',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"left-panel"},[_c('div',{staticClass:"entity-block"},[_c('div',{staticClass:"flex top-panel"},[_c('div',{staticClass:"wrapper-button"},[_c('q-btn',{staticClass:"btn-new",attrs:{"icon-right":"add","label":"Ajouter une entité"},on:{"click":_vm.openAddEntity}})],1),_c('SearchInformation',{attrs:{"placeholder":"Rechercher","loading":true},on:{"setSearchText":_vm.setSearchText}})],1),(_vm.isAddEntity)?_c('div',[_c('AddEntityBlock',{attrs:{"projectId":_vm.document.projectId,"extractedData":_vm.extractedData},on:{"close":_vm.closeAddEntity,"newEntity":_vm.newEntity}})],1):_vm._e(),_c('div',{staticClass:"entities-list"},_vm._l((_vm.extractedData),function(entity){return _c('div',{key:entity.entityId,class:{ entity: true, custom: entity.custom },attrs:{"data-entity-id":entity.entityId}},[_c('q-expansion-item',{attrs:{"value":entity.entityId === _vm.selectedEntityId ||
                      (!!entity.entityData.length && _vm.isSearch),"switch-toggle-side":"","label":entity.entityName,"default-opened":entity.entityId === _vm.selectedEntityId ||
                      (!!entity.entityData.length && _vm.isSearch)},on:{"show":function($event){_vm.showEntityId = entity.entityId},"hide":function($event){_vm.showEntityId = null}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('q-item-section',[_c('span',{staticClass:"title"},[_vm._v(_vm._s(entity.entityName))])]),_c('q-btn',{directives:[{name:"show",rawName:"v-show",value:(entity.entityId === _vm.showEntityId),expression:"entity.entityId === showEntityId"}],staticClass:"btn-white",attrs:{"icon-right":"add","label":"Ajouter une info"},on:{"click":function($event){$event.stopPropagation();return _vm.openAddInfo(entity)}}})]},proxy:true}],null,true)},[(_vm.isAddInfo)?_c('div',[_c('AddInfoBlock',{attrs:{"entity":entity},on:{"close":_vm.closeAddInfo,"newInfo":_vm.addNewInfo}})],1):_vm._e(),(entity.entityData.length)?_c('q-card',_vm._l((entity.entityData),function(item){return _c('q-card-section',{key:item.dataId,class:{
                        'entity-item': true,
                        active: item.dataId === _vm.selectedDataId,
                        custom: item.dataId.includes('CUSTOM')
                      }},[_c('div',{staticClass:"top",on:{"click":e => _vm.selectData(e, item)}},[_c('div',{staticClass:"right"},[_c('div',{staticClass:"right-left"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(item.dataLabel))]),_c('div',[_c('span',{class:['status', item.dataConfidence]}),_c('span',{staticClass:"value"},[_vm._v(_vm._s(item.dataValue))])])])])])])}),1):_c('q-card',[_c('q-card-section',{staticClass:"entity-item-empty"},[_c('div',{staticClass:"value"},[_vm._v("No data for this entity")])])],1)],1)],1)}),0)])]),(_vm.document !== null)?_c('div',{staticClass:"right-panel"},[_c('PdfBlock',{attrs:{"isUseDenominationModal":true,"selectedDataId":_vm.selectedDataId,"selectedEntityId":_vm.selectedEntityId,"document":_vm.document,"extractedData":_vm.extractedData,"selectedDataLabel":_vm.selectedDataLabel}})],1):_vm._e()])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }