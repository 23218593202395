<template>
  <div class="pdf">
    <q-page-container>
      <q-page>
        <div class="flex">
          <div class="left-panel">
            <div class="entity-block">
              <div class="flex top-panel">
                <div class="wrapper-button">
                  <q-btn
                    class="btn-new"
                    icon-right="add"
                    label="Ajouter une entité"
                    @click="openAddEntity"
                  />
                </div>
                <SearchInformation
                  @setSearchText="setSearchText"
                  placeholder="Rechercher"
                  :loading="true"
                />
              </div>
              <div v-if="isAddEntity">
                <AddEntityBlock
                  :projectId="document.projectId"
                  @close="closeAddEntity"
                  @newEntity="newEntity"
                  :extractedData="extractedData"
                />
              </div>
              <div class="entities-list">
                <div
                  :data-entity-id="entity.entityId"
                  :class="{ entity: true, custom: entity.custom }"
                  :key="entity.entityId"
                  v-for="entity in extractedData"
                >
                  <q-expansion-item
                    :value="
                      entity.entityId === selectedEntityId ||
                        (!!entity.entityData.length && isSearch)
                    "
                    switch-toggle-side
                    :label="entity.entityName"
                    :default-opened="
                      entity.entityId === selectedEntityId ||
                        (!!entity.entityData.length && isSearch)
                    "
                    @show="showEntityId = entity.entityId"
                    @hide="showEntityId = null"
                  >
                    <template v-slot:header>
                      <q-item-section>
                        <span class="title">{{ entity.entityName }}</span>
                        <!-- <q-tooltip
                          :name="entity.entityName"
                          v-if="isShowTooltip(`${entity.entityName}`)"
                        >
                          {{ entity.entityName }}</q-tooltip
                        > -->
                      </q-item-section>
                      <q-btn
                        v-show="entity.entityId === showEntityId"
                        icon-right="add"
                        label="Ajouter une info"
                        class="btn-white"
                        @click.stop="openAddInfo(entity)"
                      />
                    </template>
                    <div v-if="isAddInfo">
                      <AddInfoBlock
                        :entity="entity"
                        @close="closeAddInfo"
                        @newInfo="addNewInfo"
                      />
                    </div>
                    <q-card v-if="entity.entityData.length">
                      <q-card-section
                        v-for="item in entity.entityData"
                        :key="item.dataId"
                        :class="{
                          'entity-item': true,
                          active: item.dataId === selectedDataId,
                          custom: item.dataId.includes('CUSTOM')
                        }"
                      >
                        <div class="top" @click="e => selectData(e, item)">
                          <div class="right">
                            <div class="right-left">
                              <div class="label">{{ item.dataLabel }}</div>
                              <div>
                                <span
                                  :class="['status', item.dataConfidence]"
                                ></span>
                                <span class="value">{{ item.dataValue }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </q-card-section>
                    </q-card>
                    <q-card v-else>
                      <q-card-section class="entity-item-empty">
                        <div class="value">No data for this entity</div>
                      </q-card-section>
                    </q-card>
                  </q-expansion-item>
                </div>
              </div>
            </div>
          </div>
          <div class="right-panel" v-if="document !== null">
            <PdfBlock
              :isUseDenominationModal="true"
              :selectedDataId="selectedDataId"
              :selectedEntityId="selectedEntityId"
              :document="document"
              :extractedData="extractedData"
              :selectedDataLabel="selectedDataLabel"
            />
          </div>
        </div>
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PdfBlock from '@/components/global/PdfBlock'
import SearchInformation from '@/components/review-information/SearchInformation'
import AddEntityBlock from '@/components/review-information/AddEntityBlock'
import AddInfoBlock from '@/components/review-information/AddInfoBlock'

export default {
  name: 'MainPDF',
  components: {
    PdfBlock,
    SearchInformation,
    AddEntityBlock,
    AddInfoBlock
  },
  computed: {
    ...mapState('documents', ['loadingDocument', 'errorDocument']),
    ...mapGetters('documents', ['getDocument']),
    drawSquaresBlue() {
      return this.getDocument.ocrData
    }
  },
  data() {
    return {
      extractedData: [],
      expanded: false,
      selectedDataId: null,
      selectedEntityId: null,
      isAddEntity: false,
      isAddInfo: false,
      showEntityId: null,
      isSearch: false,
      document: null,
      selectedDataLabel: '',
      extractedDataBeforeFilters: []
    }
  },
  async created() {
    const { documentId } = this.$route.params
    this.fetchDocument({ documentId }).then(() => {
      this.setNamePDF({ name: this.getDocument.name })
    })
    this.fetchPlaceholders()
  },
  methods: {
    ...mapActions('projects', ['fetchPlaceholders']),
    ...mapActions('documents', ['fetchDocument', 'setNamePDF']),
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    selected(item) {
      if (this.$route.params.documentId !== item.sourceDocumentI) {
        this.fetchDocument({ documentId: item.sourceDocumentId })
      }
    },
    selectData(e, data) {
      if (this.selectedEntityId !== data.entityId) {
        this.$set(this, 'selectedEntityId', data.entityId)
      }
      if (data.dataId) {
        this.$set(this, 'selectedDataLabel', data.dataLabel)
        this.selectedDataId =
          this.selectedDataId === data.dataId ? null : data.dataId
      } else {
        this.selectedDataId = null
      }
    },
    setSearchText(val) {
      if (val === '') {
        this.extractedData = this.extractedDataBeforeFilters
      }

      const newExtractedData = this.extractedData.map(entity => {
        return {
          ...entity,
          entityData: entity.entityData.filter(data => {
            return (
              data.dataValue.toLowerCase().includes(val) ||
              data.dataLabel.toLowerCase().includes(val)
            )
          })
        }
      })

      if (val && val.length) {
        this.$set(this, 'isSearch', true)
      } else {
        this.$set(this, 'isSearch', false)
      }

      this.$set(this, 'extractedData', newExtractedData)
    },
    openAddEntity() {
      this.isAddEntity = true
    },
    closeAddEntity() {
      this.isAddEntity = false
    },
    openAddInfo() {
      this.isAddInfo = true
    },
    closeAddInfo() {
      this.isAddInfo = false
    },
    newEntity(entity) {
      const findEntity = this.extractedData.filter(
        _entity => _entity.entityId === entity.entityId
      )
      if (findEntity && !findEntity.length) {
        entity.custom = true
        const newExtractedData = [...this.extractedData, entity]
        this.$set(this, 'extractedData', newExtractedData)
      }
    },
    mounted() {
      this.popupItem = this.$el
    },
    addNewInfo(info) {
      const newExtractedData = [...this.extractedData].map(entity => {
        if (entity.entityId === info.entityId) {
          entity.entityData.push({
            ...info,
            dataLabel: info.dataLabel,
            dataValue: '',
            dataId: info.dataId,
            entityId: info.entityId,
            sourceDocumentDate: info.sourceDocumentDate
          })
        }
        return entity
      })
      this.selectedDataId = info.dataId
      this.selectedDataLabel = info.dataLabel
      this.selectedEntityId = info.entityId
      this.extractedData = newExtractedData
      this.extractedDataBeforeFilters = newExtractedData
    },
    isShowTooltip(title) {
      if (title.length > 30) {
        return true
      }
      return false
    }
  },
  updated: function() {
    this.$nextTick(function() {
      const el = document.querySelector('.entity-item.active.custom')
      if (el) {
        el.scrollIntoView({ block: 'center', behavior: 'smooth' })
        el.click()
      }

      const el2 = document.querySelector('.entity.custom')
      if (el2) {
        el2.scrollIntoView({ block: 'center', behavior: 'smooth' })
        this.selectedEntityId = el2.getAttribute('data-entity-id')
        el2.classList.remove('custom')
      }
    })
  },
  watch: {
    getDocument: function(newDocument, oldDocument) {
      if (
        oldDocument === null ||
        newDocument.documentId !== oldDocument.documentId
      ) {
        this.document = newDocument
      } else {
        if (this.document == null) {
          this.document = newDocument
        }
      }
    },
    errorDocument: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    'getDocument.extractedData': function(newExtractedData) {
      this.extractedData = newExtractedData
      this.extractedDataBeforeFilters = newExtractedData
    }
  }
}
</script>

<style lang="scss" scoped>
.pdf {
  .title {
    max-width: 310px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .q-expansion-item--expanded {
    .title {
      max-width: 230px;
    }
  }
  .add-info-block {
    background: #e0e7fc;

    border: 1px solid #ecedf6;

    .title {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #061748;
      margin-bottom: 8px;
    }
  }
}
</style>
